import React, { useState } from 'react'

type NewsletterFormProps = {
  onSubmit: (email: string) => void

  placeholderText: string
  buttonText: React.ReactNode
}

export const NewsletterForm: React.FC<NewsletterFormProps> = ({
  onSubmit,
  placeholderText,
  buttonText,
}) => {
  const [email, setEmail] = useState('')

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit(email)
  }

  return (
    <form onSubmit={handleSubmit} className='gtm-newsletter'>
      <div className='flex flex-row w-full flex-wrap md:flex-nowrap'>
        <input
          className='p-4 w-full rounded-xl md:rounded-none md:rounded-tl-xl md:rounded-bl-xl text-secondary1 md:flex-grow md:flex-shrink'
          placeholder={placeholderText}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type='email'
          required
        />

        <button
          className={`bg-primary pl-8 pr-8 pt-3 pb-3 md:pt-0 md:pb-0 font-bold text-md whitespace-nowrap mt-3 md:mt-0 rounded-xl md:rounded-none md:rounded-tr-xl md:rounded-br-xl w-full md:w-auto`}
        >
          {buttonText}
        </button>
      </div>
    </form>
  )
}
