import React, { useContext, useEffect } from 'react'
import * as Scroll from 'react-scroll'
import { graphql } from 'gatsby'
import Sticky from 'react-stickynode'
import {
  Layout,
  Logo,
  LogoFooter,
  FirstAppMockup,
  SecondAppMockup,
  Feature,
  OrderedList,
  MagicContent,
  Head,
} from '../components'
import { Newsletter } from '../components/Newsletter'
import { ConfigContext } from '../misc/config'
import { trackEvent, TrackEventType } from '../misc/tracking'

type IndexProps = {
  data: any
}

export const query = graphql`
  query HomePageQuery {
    allDataJson {
      nodes {
        app

        meta {
          title
          description
          url
          image
        }

        newsletter {
          title
          placeholder
          submit
          loading
          success
        }

        mainSection {
          title
          imageAlt
          subtitle
        }

        features {
          body
          emoji
          title
        }

        secondSection {
          steps
          imageAlt
          title
        }

        footer {
          contactEmail
          contactText
        }
      }
    }
  }
`

export default ({ data }: IndexProps) => {
  const content = data.allDataJson.nodes[0]
  const config = useContext(ConfigContext)

  const scrollToTop = () => Scroll.animateScroll.scrollToTop({ duration: 300 })
  const trackScroll = () => trackEvent({ name: TrackEventType.PageScrolled })
  const trackImageLoad = () => trackEvent({ name: TrackEventType.ImageLoaded })

  // Tracking page scroll
  useEffect(() => {
    window.addEventListener('scroll', trackScroll, { once: true })
  }, [])

  return (
    <>
      <Head
        title={content.meta.title}
        description={content.meta.description}
        url={content.meta.url}
        image={content.meta.image}
      />

      <Layout>
        <div className='pt-10 md:pt-20'>
          <a href='/'>
            <Logo />
          </a>
        </div>

        <div className='pt-32 flex flex-row-reverse justify-center md:justify-between w-full flex-wrap md:flex-nowrap'>
          <div className='w-full md:flex-grow-0 md:flex-shrink-0 md:w-4/12'>
            <FirstAppMockup
              alt={content.mainSection.imageAlt}
              onLoad={trackImageLoad}
            />
          </div>

          <div
            className='w-full mt-10 md:mt-0 md:flex-auto md:pr-28'
            id='sticky-container-1'
          >
            <Sticky enabled={true} bottomBoundary='#sticky-container-1'>
              <div className='md:pt-24 md:pb-24'>
                <h1 className='text-primary text-5xl leading-normal md:text-8xl font-black'>
                  {content.mainSection.title}
                </h1>

                <div className='text-white text-3xl md:text-5xl mt-10 max-w-3xl leading-normal md:leading-normal'>
                  <MagicContent>{content.mainSection.subtitle}</MagicContent>
                </div>

                <div className='mt-16 md:mt-24 max-w-2xl'>
                  <Newsletter
                    url={config.mailchimpUrl}
                    placeholderText={content.newsletter.placeholder}
                    buttonText={content.newsletter.submit}
                    successText={content.newsletter.success}
                    loadingText={content.newsletter.loading}
                  />
                </div>
              </div>
            </Sticky>
          </div>
        </div>
      </Layout>
      <div className='pt-44 pb-44 mt-44 bg-secondary0'>
        <Layout>
          <div className='flex flex-row flex-wrap'>
            <div className='w-full md:w-1/2 flex-grow-0 flex-shrink-0 md:pr-36'>
              <Feature
                emoji={content.features[0].emoji}
                title={content.features[0].title}
                body={<MagicContent>{content.features[0].body}</MagicContent>}
              />
            </div>

            <div className='w-full mt-24 md:mt-0 md:w-1/2 flex-grow-0 flex-shrink-0 md:pr-36'>
              <Feature
                emoji={content.features[1].emoji}
                title={content.features[1].title}
                body={<MagicContent>{content.features[1].body}</MagicContent>}
              />
            </div>
          </div>

          <div className='flex flex-row flex-wrap mt-24 md:mt-48'>
            <div className='w-full md:w-1/2 flex-grow-0 flex-shrink-0 md:pr-36'>
              <Feature
                emoji={content.features[2].emoji}
                title={content.features[2].title}
                body={<MagicContent>{content.features[2].body}</MagicContent>}
              />
            </div>

            <div className='w-full mt-24 md:mt-0 md:w-1/2 flex-grow-0 flex-shrink-0 md:pr-36'>
              <Feature
                emoji={content.features[3].emoji}
                title={content.features[3].title}
                body={<MagicContent>{content.features[3].body}</MagicContent>}
              />
            </div>
          </div>
        </Layout>
      </div>

      <Layout>
        <div className='pt-44 flex justify-center md:justify-between w-full flex-wrap md:flex-nowrap'>
          <div className='w-full md:flex-grow-0 md:flex-shrink-0 md:w-4/12'>
            <SecondAppMockup alt={content.secondSection.imageAlt} />
          </div>

          <div
            className='flex-auto mt-10 md:mt-0 md:pl-28'
            id='sticky-container-2'
          >
            <Sticky enabled={true} bottomBoundary='#sticky-container-2'>
              <div className='md:pt-24 md:pb-24'>
                <h2 className='text-primary text-4xl md:text-6xl font-black'>
                  {content.secondSection.title}
                </h2>

                <div className='mt-10 ml-7'>
                  <OrderedList
                    items={[
                      <MagicContent>
                        {content.secondSection.steps[0]}
                      </MagicContent>,
                      <MagicContent>
                        {content.secondSection.steps[1]}
                      </MagicContent>,
                      <MagicContent>
                        {content.secondSection.steps[2]}
                      </MagicContent>,
                      <MagicContent>
                        {content.secondSection.steps[3]}
                      </MagicContent>,
                    ]}
                  />
                </div>
              </div>
            </Sticky>
          </div>
        </div>
      </Layout>

      <div className='bg-secondary0 pt-44 pb-44 mt-44'>
        <Layout>
          <div className='w-full'>
            <div className='text-primary text-3xl md:text-6xl font-black text-center leading-normal'>
              {content.newsletter.title}
            </div>

            <div className='max-w-2xl mt-10 md:mt-16 ml-auto mr-auto'>
              <Newsletter
                url={config.mailchimpUrl}
                placeholderText={content.newsletter.placeholder}
                buttonText={content.newsletter.submit}
                successText={content.newsletter.success}
                loadingText={content.newsletter.loading}
              />
            </div>
          </div>
        </Layout>
      </div>

      <Layout>
        <footer className='flex flex-wrap md:flex-nowrap flex-row justify-between items-center pt-16 pb-16'>
          <div className='w-full md:w-auto flex flex-shrink-0 flex-grow-0 flex-row items-center'>
            <a
              href='#'
              onClick={scrollToTop}
              className='ml-auto md:ml-0 mr-auto md:mr-0'
            >
              <LogoFooter />
            </a>
          </div>

          <div className='mt-5 md:mt-0 w-full md:w-auto flex-shrink-0 flex-grow-0 text-xs md:text-sm text-secondary4 text-center'>
            <a
              href={`mailto:${content.footer.contactEmail}`}
              className='mr-5 md:mr-10 uppercase underline'
            >
              {content.footer.contactText}
            </a>

            <span className='uppercase'>
              © {new Date().getFullYear()} {content.app}
            </span>
          </div>
        </footer>
      </Layout>
    </>
  )
}
