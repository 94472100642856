import React from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { trackEvent, TrackEventType } from '../../misc/tracking'
import { NewsletterForm } from './NewsletterForm'

type NewsletterProps = {
  url: string

  placeholderText: string
  buttonText: React.ReactNode
  successText: React.ReactNode
  loadingText: React.ReactNode
}

export const Newsletter: React.FC<NewsletterProps> = ({
  url,
  placeholderText,
  buttonText,
  successText,
  loadingText,
}) => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status }) => {
      const handleSubmit = (email: string) => {
        subscribe({ EMAIL: email })
        trackEvent({ name: TrackEventType.EmailSubmitted, email })
      }

      return (
        <div className='relative'>
          <NewsletterForm
            onSubmit={handleSubmit}
            placeholderText={placeholderText}
            buttonText={buttonText}
          />

          {status === 'error' || status === 'success' ? (
            <div className='absolute -bottom-10'>{successText}</div>
          ) : status === 'sending' ? (
            <div className='absolute -bottom-10'>{loadingText}</div>
          ) : undefined}
        </div>
      )
    }}
  />
)
